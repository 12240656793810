import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  baseUrl,
  deleteMethod,
  patchMethod,
  postMethod,
  session,
} from "./BaseUrl";
import {
  addBatchDepartmentFunction,
  addClassDepartmentFunction,
  addClassMasterDepartmentFunction,
  addDepartmentFunction,
  addSubjectClassDepartmentFunction,
  addSubjectMasterDepartmentFunction,
  allBatchDepartmentFunction,
  allClassMasterDepartmentFunction,
  allDepartmentFunction,
  allSubjectMasterDepartmentFunction,
  currentBatchDepartmentFunction,
  oneBatchClassDepartmentFunction,
  oneClassProfileDepartmentFunction,
  oneDepartmentFunction,
  setCurrentBatchDepartmentFunction,
  oneClassAllSubjectDepartmentFunction,
  allApproveStaffDepartmentFunction,
  instituteProfileFunction,
  updateInstituteProfileFunction,
  addIdenticalBatchDepartmentFunction,
  ///////////
  getDetailDepartmentFunction,
  editDepartmentFunction,
  deleteDepartmentFunction,
  editBatchFunction,
  deleteBatchFunction,
  deleteClassMasterFunction,
  getDetailClassFunction,
  editClassFunction,
  deleteClassFunction,
  deleteSubjectMasterFunction,
  getDetailSubjectFunction,
  editSubjectFunction,
  deleteSubjectFunction,

  ///////////
  instituteAllStaffComplaintFunction,
  instituteStaffComplaintDetailFunction,
  instituteStaffComplaintSolveFunction,
  instituteStaffFormFunction,
  instituteStudentFormFunction,
  instituteFormSettingUpdateFunction,
  /////////////////
  userProfileFunction,
  updateUserProfileFunction,

  ///
  staffProfilePhotoFunction,
  staffFormEditFunction,
} from "./QueryMutationFunctionDepartment";

export const instituteDepartmentApi = createApi({
  reducerPath: "instituteDepartmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      if (session()) {
        // headers.set("SessionID", session());
        headers.set("authorization", session());
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    allDepartment: allDepartmentFunction(builder),
    addDepartment: addDepartmentFunction(builder, postMethod),
    viewDepartment: oneDepartmentFunction(builder),
    allBatch: allBatchDepartmentFunction(builder),
    addNewBatch: addBatchDepartmentFunction(builder, postMethod),
    addNewIdenitcalBatch: addIdenticalBatchDepartmentFunction(
      builder,
      postMethod
    ),
    currentBatch: currentBatchDepartmentFunction(builder),
    setCurrentBatch: setCurrentBatchDepartmentFunction(builder, postMethod),
    addClassMaster: addClassMasterDepartmentFunction(builder, postMethod),
    allClassMaster: allClassMasterDepartmentFunction(builder),
    addSubjectMaster: addSubjectMasterDepartmentFunction(builder, postMethod),
    allSubjectMaster: allSubjectMasterDepartmentFunction(builder),
    addClass: addClassDepartmentFunction(builder, postMethod),
    allClass: oneBatchClassDepartmentFunction(builder),
    addSubjectClass: addSubjectClassDepartmentFunction(builder, postMethod),
    classProfile: oneClassProfileDepartmentFunction(builder),
    allSubjectOneClass: oneClassAllSubjectDepartmentFunction(builder),
    allApproveStaff: allApproveStaffDepartmentFunction(builder),
    instituteProfile: instituteProfileFunction(builder),
    updateInstituteProfile: updateInstituteProfileFunction(
      builder,
      patchMethod
    ),

    // For department EDIT AND DELETE
    editDetailDepartment: getDetailDepartmentFunction(builder),
    editDepartment: editDepartmentFunction(builder, patchMethod),
    deleteDepartment: deleteDepartmentFunction(builder, deleteMethod),
    editBatcht: editBatchFunction(builder, patchMethod),
    deleteBatch: deleteBatchFunction(builder, deleteMethod),
    deleteClassMaste: deleteClassMasterFunction(builder, deleteMethod),
    editDetailClass: getDetailClassFunction(builder),
    editClass: editClassFunction(builder, patchMethod),
    deleteClass: deleteClassFunction(builder, deleteMethod),
    deleteSubjectMaster: deleteSubjectMasterFunction(builder, deleteMethod),
    editDetailSubject: getDetailSubjectFunction(builder),
    editSubject: editSubjectFunction(builder, patchMethod),
    deleteSubject: deleteSubjectFunction(builder, deleteMethod),

    // For Institute Staff Complaint
    instituteAllStaffComplaint: instituteAllStaffComplaintFunction(builder),
    instituteStaffComplaintDetail:
      instituteStaffComplaintDetailFunction(builder),
    instituteStaffComplaintSolve: instituteStaffComplaintSolveFunction(
      builder,
      patchMethod
    ),
    //FOR THE INSTITUTE Form Setting OF STAFF and STUDENT
    instituteStaffForm: instituteStaffFormFunction(builder),
    instituteStudentForm: instituteStudentFormFunction(builder),
    instituteFormSettingUpdate: instituteFormSettingUpdateFunction(
      builder,
      patchMethod
    ),

    userProfile: userProfileFunction(builder),
    updateUserProfile: updateUserProfileFunction(builder, patchMethod),

    // for staff form Edit
    staffProfilePhoto: staffProfilePhotoFunction(builder, patchMethod),
    staffFormEdit: staffFormEditFunction(builder, patchMethod),
  }),
});

export const {
  useAllDepartmentQuery,
  useAddDepartmentMutation,
  useViewDepartmentQuery,
  useAllBatchQuery,
  useAddNewBatchMutation,
  useAddNewIdenitcalBatchMutation,
  useCurrentBatchQuery,
  useSetCurrentBatchMutation,
  useAddClassMasterMutation,
  useAllClassMasterQuery,
  useAddSubjectMasterMutation,
  useAllSubjectMasterQuery,
  useAddClassMutation,
  useAllClassQuery,
  useAddSubjectClassMutation,
  useClassProfileQuery,
  useAllSubjectOneClassQuery,
  useAllApproveStaffQuery,
  useInstituteProfileQuery,
  useUpdateInstituteProfileMutation,

  //for department delete
  useEditDetailDepartmentQuery,
  useEditDepartmentMutation,
  useDeleteDepartmentMutation,
  useEditBatchtMutation,
  useDeleteBatchMutation,
  useDeleteClassMasteMutation,
  useEditDetailClassQuery,
  useEditClassMutation,
  useDeleteClassMutation,
  useDeleteSubjectMasterMutation,
  useEditDetailSubjectQuery,
  useEditSubjectMutation,
  useDeleteSubjectMutation,

  // For Institute Staff Complaint

  useInstituteAllStaffComplaintQuery,
  useInstituteStaffComplaintDetailQuery,
  useInstituteStaffComplaintSolveMutation,
  //FOR THE INSTITUTE Form Setting OF STAFF and STUDENT
  useInstituteStaffFormQuery,
  useInstituteStudentFormQuery,
  useInstituteFormSettingUpdateMutation,
  ///////////////////

  useUserProfileQuery,
  useUpdateUserProfileMutation,
  useStaffProfilePhotoMutation,
  useStaffFormEditMutation,
} = instituteDepartmentApi;
