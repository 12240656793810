const postUrl = "/api/v1";
var id = localStorage.getItem("user");

// -------------------Switch User Account--------------------------------------------

export const switchUserAccount = (builder) => {
  return builder.query({
    query: () => `${postUrl}/user/${id}/staff/student/role`,
  });
};
