import { useDispatch, useSelector } from "react-redux";
import { authChangeAction } from "../redux/store/authenticate-slice";


export const AutoLoginQuery = (data) =>{
    const author = useSelector((state) => state.authChange)
    const dispatch = useDispatch();
    const token = localStorage.getItem('token') ? localStorage.getItem('token').split(' ')[1] : author.token ? author.token.split(' ')[1] : ''
    if(token === null){
        dispatch(
        authChangeAction.loginQuery({
        login: false,
        }))
    }
    else if(data && data.status){
        dispatch(
        authChangeAction.loginQuery({
        login: data && data.status,
        token: `Bearer ${data && data.token}`
        }))
        localStorage.setItem('token', `Bearer ${data && data.token}`)
        localStorage.setItem('status', true)
    }
}