import React, { Suspense, lazy } from "react";
import styles from "./Loader.module.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "../App.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import ProtectedRoutes from "./ProtectedRoutes";
// import DOMPurify from 'dompurify';
import NoInternet from "../NoInternet/NoInternet";
import QLoader from "../Loader/QLoader";
const InsDetail = lazy(() =>
  import("../SuperAdmin/MainContent/InsDetail/InsDetail")
);
const Login = lazy(() => import("../Authentication/Login/Login"));
const SignUp = lazy(() => import("../Authentication/Signup/SignUp"));

const InsDashboard = lazy(() => import("../Dashboard/Dashboard"));

const InsProfileSetting = lazy(() =>
  import("../ProfileSetting/ProfileSetting")
);
const InsNotification = lazy(() => import("../Notification/Notificattion"));

const InsProfile = lazy(() => import("../Profile/Profile"));
const InsFollowAndFollowing = lazy(() =>
  import("../Profile/Institute/Pages/FollowAndFollowing")
);
const InsEditProfile = lazy(() => import("../Profile/Edit"));
const InsDepartments = lazy(() =>
  import("../Department/Institute/Pages/Department")
);
const InsClass = lazy(() =>
  import("../Department/Institute/Pages/ClassProfile")
);
const StaffTab = lazy(() => import("../StaffTab/StaffTab"));
const SuperAdmin = lazy(() => import("../SuperAdmin/SuperAdmin"));
const Terms = lazy(() => import("../TAC/Terms"));
const Privacy = lazy(() => import("../Privacy/Privacy"));
const Error = lazy(() => import("../Error/Error"));
// -----------------Members Tab----------------------------------------

// -----------------Finance----------------------------------------
const InsFinance = lazy(() => import("../Finance/Institute/InsFinance"));

const Certificates = lazy(() => import("../Certificates/Certificates"));

// -----------------Admision Admin----------------------------------------
const AdmisionAdmin = lazy(() =>
  import("../AdmisionAdmin/InstituteAdmisionAdmin")
);

// -----------------Ins Sports and Arts----------------------------------------
const InsSportsAndArts = lazy(() =>
  import("../SportsAndArts/InsSportsAndArts/InsSportsAndArts")
);

function AllRoute({ loggedIn }) {
  axios.defaults.withCredentials = true;
  // const sanitizedBlog=DOMPurify.sanitize(content to be sanitize)

  return (
    <Suspense
      fallback={
        <QLoader />
        // <div className={styles.loader} style={{ color: "black" }}>
        //   <img src="/images/career/newLogoText.svg" alt="qviple" />
        // </div>
      }
    >
      <div className="App">
        <Router>
          <Routes>
            {`${window.navigator.onLine}` === "true" ? (
              <>
                <Route path="/" exact element={<Login />} />
                <Route path="/login" exact element={<Login />} />
                <Route path="/signup" exact element={<SignUp />} />

                <Route
                  path="/q/:username/feed"
                  exact
                  element={
                    <ProtectedRoutes isLoggedIn={loggedIn && loggedIn}>
                      <InsDashboard />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/q/:username/profile"
                  exact
                  element={
                    <ProtectedRoutes isLoggedIn={loggedIn && loggedIn}>
                      <InsProfile />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/q/:username/dash/i/:name/profile"
                  exact
                  element={
                    <ProtectedRoutes isLoggedIn={loggedIn && loggedIn}>
                      <InsDetail />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/q/:username/following"
                  exact
                  element={
                    <ProtectedRoutes isLoggedIn={loggedIn && loggedIn}>
                      <InsFollowAndFollowing />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/q/:username/viewdept"
                  exact
                  element={
                    <ProtectedRoutes isLoggedIn={loggedIn && loggedIn}>
                      <InsDepartments />
                    </ProtectedRoutes>
                  }
                />

                <Route
                  path="/q/:username/class"
                  exact
                  element={
                    <ProtectedRoutes isLoggedIn={loggedIn && loggedIn}>
                      <InsClass />
                    </ProtectedRoutes>
                  }
                />

                <Route
                  path="/q/:username/dash/i/:name/admission"
                  exact
                  element={
                    <ProtectedRoutes isLoggedIn={loggedIn && loggedIn}>
                      <AdmisionAdmin />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path="/q/:username/dashboard"
                  exact
                  element={
                    <ProtectedRoutes isLoggedIn={loggedIn && loggedIn}>
                      <SuperAdmin />
                    </ProtectedRoutes>
                  }
                />
                <Route path="/q/terms/condition" exact element={<Terms />} />
                <Route path="/q/privacy/policy" exact element={<Privacy />} />
                <Route
                  path="/q/:username/dash/i/:name/finance"
                  exact
                  element={<InsFinance />}
                />

                {/* <Route
                  path="/q/:username/sportsandarts"
                  exact
                  element={<InsSportsAndArts />}
                /> */}

                <Route path="*" exact element={<Error />} />
              </>
            ) : (
              <Route path="*" exact element={<NoInternet />} />
            )}
          </Routes>
        </Router>
      </div>
    </Suspense>
  );
}

export default AllRoute;
