export const manageAuthentication = (builder) => {
  return builder.mutation({
    query: (login) => ({
      url: "/manage/admin/login",
      method: "POST",
      body: login,
    }),
  });
};

export const manageDashboard = (builder) => {
  return builder.query({
    query: (manage) => `/manage/admin/${manage}/query`,
  });
};

export const manageInstitute = (builder) => {
  return builder.query({
    query: (admin) =>
      `/manage/admin/${admin?.mid}/all/ins/query?page=${admin.info.page}&limit=${admin.info.limit}`,
  });
};

export const manageRequestAll = (builder) => {
  return builder.query({
    query: (admin) => `/manage/admin/${admin}/all/request`,
  });
};

export const manageFinance = (builder) => {
  return builder.query({
    query: (finance) => `/manage/admin/${finance}/finance`,
  });
};

export const manageAdmission = (builder) => {
  return builder.query({
    query: (admission) => `/manage/admin/${admission}/admission`,
  });
};

export const manageUser = (builder) => {
  return builder.query({
    query: (data) => ({
      url: `/manage/admin/all/user?search=${data.search.search}&page=${data.search.page}&limit=${data.search.limit}`,
    }),
  });
};

export const oneInstituteProfile = (builder) => {
  return builder.query({
    query: (id) => `/manage/admin/one/institute/${id}`,
  });
};

export const manageStatus = (builder) => {
  return builder.mutation({
    query: (manage) => ({
      url: `/manage/admin/${manage?.mid}/status?status_ins=${manage?.status_ins}&status=${manage?.status}`,
      method: "POST",
    }),
  });
};
