import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseUrl, prepareHeaders } from "../configApi";
import {
    activateAdmission,
    fetchAdmission,
    fetchOngoing,
    fetchCompleted,
    fetchRemainingArray,
    atInstituteSearchApplication,
    userApply,
    newApplication,
    fetchRequest,
    fetchSelect,
    fetchConfirm,
    userSelect,
    userSelectStatusMode,
    userConfirm,
    userCancel,
    fetchClasses,
    newClassAllot,
    markComplete,
    oneStudentFee,
    paidRemainFee,
    oneApplication,
    allDepartment,
    cancelStatusMode
} from "./admissionBuilderAPI";

export const admissionAPI = createApi({
  reducerPath: "admissionAPI",
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders: prepareHeaders }),
  endpoints: (builder) => ({
    activateAdmissionHead: activateAdmission(builder),
    fetchAdmissionDetail: fetchAdmission(builder),
    fetchAllOngoingApp: fetchOngoing(builder),
    fetchAllCompletedApp: fetchCompleted(builder),
    fetchAllRemainingArray: fetchRemainingArray(builder),
    fetchAllAppAtInstitute: atInstituteSearchApplication(builder),
    newUserApply: userApply(builder),
    getNewApplication: newApplication(builder),
    getAllRequest: fetchRequest(builder),
    getAllSelect: fetchSelect(builder),
    getAllConfirm: fetchConfirm(builder),
    fetchUserSelectApp: userSelect(builder),
    fetchUserSelectStatusApp: userSelectStatusMode(builder),
    getUserConfirm: userConfirm(builder),
    getUserCancel: userCancel(builder),
    getAllClasses: fetchClasses(builder),
    retrieveNewClassAllot: newClassAllot(builder),
    retrieveMarkComplete: markComplete(builder),
    getOneViewStudentFee: oneStudentFee(builder),
    retrievePaidRemainFee: paidRemainFee(builder),
    getOneApplication: oneApplication(builder),
    getAllDepartment: allDepartment(builder),
    fetchCancelStatusMode: cancelStatusMode(builder),
  }),
});

export const {
    useActivateAdmissionHeadMutation,
    useFetchAdmissionDetailQuery,
    useFetchAllOngoingAppQuery,
    useFetchAllCompletedAppQuery,
    useFetchAllRemainingArrayQuery,
    useFetchAllAppAtInstituteQuery,
    useNewUserApplyMutation,
    useGetNewApplicationMutation,
    useGetAllRequestQuery,
    useGetAllSelectQuery,
    useGetAllConfirmQuery,
    useFetchUserSelectAppMutation,
    useFetchUserSelectStatusAppMutation,
    useGetUserConfirmMutation,
    useGetUserCancelMutation,
    useGetAllClassesQuery,
    useRetrieveNewClassAllotMutation,
    useRetrieveMarkCompleteMutation,
    useGetOneViewStudentFeeQuery,
    useRetrievePaidRemainFeeMutation,
    useGetOneApplicationQuery,
    useGetAllDepartmentQuery,
    useFetchCancelStatusModeMutation
} = admissionAPI;
