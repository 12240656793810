import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, prepareHeaders } from "./configApi";
import {
  authentication,
  registerPhoneNumber,
  verifyPhoneNumber,
  getInTouchPage,
  addRecoveryMail,
  addUserRecoveryMail,
  addRecoveryPhoneNumber,
  addUserRecoveryPhoneNumber,
  deactivate,
  verifyForgotUserName,
  oneInstituteProfile,
  oneUserProfile,
  oneUserProfileUsername,
  approveStaffArray,
  pendingStaffArray,
  oneStaffProfile,
  approveStudentArray,
  oneStudentProfile,
  instituteFollowersArray,
  instituteFollowingArray,
  refetchFollowingArray,
  verifyForgotOTP,
  forgotNewPassword,
  fetchAnnouncementArray,
  fetchUserAnnouncementArray,
  fetchStarAnnouncement,
  oneAnnouncementDetail,
  oneDashboard,
  oneUserDashboard,
  fetchDisplayPerson,
  addDisplayPerson,
  updateDisplayPerson,
  deleteDisplayPerson,
  fetchAllNotification,
  fetchAllUserNotification,
  markStar,
  createPassword,
  createUserPassword,
  searchStaff,
  searchStudent,
  universalSearchArray,
  staffApprove,
  staffReject,
  checkLogin,
  registerInstituteAccount,
  registerUserAccount,
  randomCodeArray,
  staffCode,
  userArray,
  userAgeRestriction,
  getUserAgeRestriction,
  feesArray,
} from "./qvipleAPIBuilder";

export const qvipleApi = createApi({
  reducerPath: "qvipleApi",
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders: prepareHeaders }),
  endpoints: (builder) => ({
    postLogin: authentication(builder),
    registerPhone: registerPhoneNumber(builder),
    verifyPhone: verifyPhoneNumber(builder),
    postGetInTouch: getInTouchPage(builder),
    postRecoveryMail: addRecoveryMail(builder),
    postUserRecoveryMail: addUserRecoveryMail(builder),
    postRecoveryPhoneNumber: addRecoveryPhoneNumber(builder),
    postUserRecoveryPhoneNumber: addUserRecoveryPhoneNumber(builder),
    postDeactivateAccount: deactivate(builder),
    verifyUserName: verifyForgotUserName(builder),
    getOneInsProfile: oneInstituteProfile(builder),
    getOneUserProfile: oneUserProfile(builder),
    getOneUserProfileUsername: oneUserProfileUsername(builder),
    staffApproveArray: approveStaffArray(builder),
    staffPendingArray: pendingStaffArray(builder),
    getOneStaffProfile: oneStaffProfile(builder),
    studentApproveArray: approveStudentArray(builder),
    getOneStudentProfile: oneStudentProfile(builder),
    getInsFollowersArray: instituteFollowersArray(builder),
    getInsFollowingArray: instituteFollowingArray(builder),
    updateFollowingArray: refetchFollowingArray(builder),
    verifyOTP: verifyForgotOTP(builder),
    newPassword: forgotNewPassword(builder),
    getAnnouncementArray: fetchAnnouncementArray(builder),
    getUserAnnouncementArray: fetchUserAnnouncementArray(builder),
    getStarAnnouncementArray: fetchStarAnnouncement(builder),
    getOneAnnouncement: oneAnnouncementDetail(builder),
    getSingleInsData: oneDashboard(builder),
    getSingleUserData: oneUserDashboard(builder),
    getDisplayPerson: fetchDisplayPerson(builder),
    addDisplayPerson: addDisplayPerson(builder),
    editDisplayPerson: updateDisplayPerson(builder),
    deleteDisplayPerson: deleteDisplayPerson(builder),
    getAllNotifications: fetchAllNotification(builder),
    getAllUserNotifications: fetchAllUserNotification(builder),
    markStarAnnouncement: markStar(builder),
    createPassword: createPassword(builder),
    createUserPassword: createUserPassword(builder),
    staffSearch: searchStaff(builder),
    studentSearch: searchStudent(builder),
    universalSearch: universalSearchArray(builder),
    markAsApprove: staffApprove(builder),
    markAsReject: staffReject(builder),
    checkLoginUser: checkLogin(builder),
    registerInstitute: registerInstituteAccount(builder),
    registerUser: registerUserAccount(builder),
    randomCode: randomCodeArray(builder),
    instituteStaffCode: staffCode(builder),
    getAllUserArray: userArray(builder),
    patchUserRestrictAge: userAgeRestriction(builder),
    getUsserAgeRestriction: getUserAgeRestriction(builder),
    getStudentFeesArray: feesArray(builder),
  }),
});

export const {
  usePatchUserRestrictAgeMutation,
  useGetUsserAgeRestrictionQuery,
  usePostLoginMutation,
  useRegisterPhoneMutation,
  useVerifyPhoneMutation,
  usePostGetInTouchMutation,
  usePostRecoveryMailMutation,
  usePostUserRecoveryMailMutation,
  usePostRecoveryPhoneNumberMutation,
  usePostUserRecoveryPhoneNumberMutation,
  useVerifyUserNameMutation,
  useGetOneInsProfileQuery,
  useGetOneUserProfileQuery,
  useGetOneUserProfileUsernameQuery,
  useGetSingleInsDataQuery,
  useGetSingleUserDataQuery,
  useGetDisplayPersonQuery,
  useEditDisplayPersonMutation,
  useAddDisplayPersonMutation,
  useDeleteDisplayPersonMutation,
  useGetAllNotificationsQuery,
  useGetAllUserNotificationsQuery,
  useStaffPendingArrayQuery,
  useStaffApproveArrayQuery,
  useGetOneStaffProfileQuery,
  useStudentApproveArrayQuery,
  useGetOneStudentProfileQuery,
  useGetInsFollowersArrayQuery,
  useGetInsFollowingArrayQuery,
  useUpdateFollowingArrayMutation,
  useVerifyOTPMutation,
  useNewPasswordMutation,
  useGetAnnouncementArrayQuery,
  useGetUserAnnouncementArrayQuery,
  useGetStarAnnouncementArrayQuery,
  useGetOneAnnouncementQuery,
  useMarkStarAnnouncementMutation,
  useCreatePasswordMutation,
  useCreateUserPasswordMutation,
  useStaffSearchQuery,
  useStudentSearchQuery,
  useUniversalSearchQuery,
  useMarkAsApproveMutation,
  useMarkAsRejectMutation,
  useCheckLoginUserQuery,
  useRegisterInstituteMutation,
  useRegisterUserMutation,
  useRandomCodeMutation,
  useInstituteStaffCodeQuery,
  useGetAllUserArrayQuery,
  useGetStudentFeesArrayQuery,
} = qvipleApi;
