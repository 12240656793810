import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseUrl, prepareHeaders } from "./configApi";
import {
  oneDashboard,
  approveArray,
  pendingArray,
  userArray,
  oneInstituteProfile,
  markApprove,
  markReject,
  careerArray,
  universalInstitute,
  adminCount,
  recentChat,
  fetchSupportMessage,
  activateInstitute,
  internalPayment,
  activateInstituteVolume,
  referralPayment,
  fetchPayment,
  verifyPayment,
  getTouch,
  rePayInstitute,
  carrierData,
  reportPost,
  notificationData,
  notificationCountData,
  repayArray,
  statsCount,
  likeCount,
  openOneInstitute,
  blockStatus,
  openOneUser,
  blockUserStatus,
  platformPosts,
  postStatus
} from "./superAdminAPIBuilder";

export const superAdminAPI = createApi({
  reducerPath: "superAdminAPI",
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders: prepareHeaders }),
  tagTypes: ['Admin'],
  endpoints: (builder) => ({
    getAdminDashboard: oneDashboard(builder),
    getApproveInstituteArray: approveArray(builder),
    getPendingInstituteArray: pendingArray(builder),
    getUserArray: userArray(builder),
    getOneInstitute: oneInstituteProfile(builder),
    markApproveInstitute: markApprove(builder),
    markRejectInstitute: markReject(builder),
    careerDataLanding: careerArray(builder),
    universalMarkInstitute: universalInstitute(builder),
    getAdminCount: adminCount(builder),
    getAdminRecentChat: recentChat(builder),
    fetchSupportChatMessage: fetchSupportMessage(builder),
    activateArray: activateInstitute(builder), 
    internalPaymentFee: internalPayment(builder),
    activateInstituteVolumeData: activateInstituteVolume(builder),
    getReferralPayment: referralPayment(builder),
    fetchReferralPayment: fetchPayment(builder),
    fetchVerifyPaymentBank: verifyPayment(builder),
    getInTouch: getTouch(builder),
    getRepayAmount: rePayInstitute(builder),
    carrierData: carrierData(builder),
    getReportData: reportPost(builder),
    getNotification: notificationData(builder),
    getNotificationCount: notificationCountData(builder),
    getRepayAmountHistory: repayArray(builder),
    getAdminStats: statsCount(builder),
    getAdminLike: likeCount(builder),
    getAdminOpenOneInstitute: openOneInstitute(builder),
    getAdminOneInstituteBlock: blockStatus(builder),
    getAdminOpenOneUser: openOneUser(builder),
    getAdminOneUserBlock: blockUserStatus(builder),
    getAllPlatformPosts: platformPosts(builder),
    getAdminOnePostBlock: postStatus(builder)
  }),
});

export const {
  useGetAdminDashboardQuery,
  useGetApproveInstituteArrayQuery,
  useGetPendingInstituteArrayQuery,
  useGetUserArrayQuery,
  useGetOneInstituteQuery,
  useMarkApproveInstituteMutation,
  useMarkRejectInstituteMutation,
  useCareerDataLandingMutation,
  useUniversalMarkInstituteMutation,
  useGetAdminCountQuery,
  useGetAdminRecentChatQuery,
  useFetchSupportChatMessageQuery,
  useActivateArrayQuery,
  useInternalPaymentFeeQuery,
  useActivateInstituteVolumeDataQuery,
  useGetReferralPaymentQuery, 
  useFetchReferralPaymentMutation,
  useFetchVerifyPaymentBankMutation,
  useGetInTouchQuery,
  useGetRepayAmountMutation,
  useCarrierDataQuery,
  useGetReportDataQuery,
  useGetNotificationQuery,
  useGetNotificationCountQuery,
  useGetRepayAmountHistoryQuery,
  useGetAdminStatsQuery,
  useGetAdminLikeQuery,
  useGetAdminOpenOneInstituteQuery,
  useGetAdminOneInstituteBlockMutation,
  useGetAdminOpenOneUserQuery,
  useGetAdminOneUserBlockMutation,
  useGetAllPlatformPostsQuery,
  useGetAdminOnePostBlockMutation
} = superAdminAPI;
