import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  baseUrl,
  // deleteMethod,
  patchMethod,
  // postMethod,
  session,
} from "../BaseUrl";
import {
  privacySettingPatchFunction,
  userDeActivateAccount,
  userQCoins,
} from "./QueryMutationFunctionSetting";

export const settingApi = createApi({
  reducerPath: "settingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      if (session()) {
        headers.set("authorization", session());
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    institutePrivacyUpdate: privacySettingPatchFunction(builder, patchMethod),
    deactivateUserAccount: userDeActivateAccount(builder),
    getUserCoins: userQCoins(builder),
  }),
});

export const {
  useInstitutePrivacyUpdateMutation,
  useDeactivateUserAccountMutation,
  useGetUserCoinsQuery,
} = settingApi;
