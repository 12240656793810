import React from "react";
import AllRoute from "./Routes";
import "./App.css";
import { useCheckLoginUserQuery } from "./services/qvipleAPI";
import { useSelector } from "react-redux";
import { AutoLoginQuery } from "./Authentication/AutoLogin";
import { useEffect } from "react";
import { GlobalDebug } from "./utils/console";

function App() {
  const author = useSelector((state) => state.authChange);
  const { data } = useCheckLoginUserQuery();
  AutoLoginQuery(data && data);

  useEffect(() => {
    GlobalDebug(false);
  }, []);

  return (
    <div className="body">
      <AllRoute loggedIn={author && author.login} />
    </div>
  );
}

export default App;
