export const activateFinance = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/finance/ins/${data.id}/staff/${data.sid}`,
      method: "POST",
    }),
  });
};

export const editFinanceFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/edit/staff/finance/staff/${data.osid}?nsid=${data.nsid}`,
      method: "PATCH",
    }),
  });
};

export const fetchFinance = (builder) => {
  return builder.query({
    query: (finance) => `/finance/${finance}/dashboard`,
  });
};

export const uploadBank = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/finance/add/bank/details/${data.id}`,
      method: "POST",
      body: data.passbook,
    }),
  });
};

export const uploadEditBank = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/finance/bank/details/${data.id}/update`,
      method: "PATCH",
      body: data.editPassbook,
    }),
  });
};

export const removeBankDetail = (builder) => {
  return builder.mutation({
    query: (id) => ({
      url: `/finance/ins/bank/${id}`,
      method: "POST",
    }),
  });
};

export const paymentDetail = (builder) => {
  return builder.query({
    query: (finance) => `/finance/${finance}/ins/bank/query`,
  });
};

export const remainBalance = (builder) => {
  return builder.query({
    query: (finance) => `/finance/${finance}/dashboard/remain`,
  });
};

export const dueBalance = (builder) => {
  return builder.query({
    query: (data) =>
      `/admin/repay/query/institute/${data.info.fid}?page=${data.info?.page}&limit=${data.info?.limit}`,
  });
};

export const remainStudentList = (builder) => {
  return builder.query({
    query: (data) =>
      `/finance/${data.finance}/remaining/fee/list?page=${data.page}&limit=${data.limit}`,
  });
};

export const submissionRequest = (builder) => {
  return builder.query({
    query: (finance) => `/finance/${finance}/dashboard/request/class`,
  });
};

export const acceptQuery = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/finance/${data?.fid}/class/${data?.cid}/fee/${data?.fiid}/submit`,
      method: "POST",
      body: data,
    }),
  });
};

export const rejectQuery = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/finance/${data?.fid}/class/${data?.cid}/fee/${data?.fiid}/incorrect`,
      method: "POST",
      body: data,
    }),
  });
};

export const acceptArray = (builder) => {
  return builder.query({
    query: (finance) => `/finance/${finance}/dashboard/submit/class`,
  });
};

export const rejectArray = (builder) => {
  return builder.query({
    query: (finance) => `/finance/${finance}/dashboard/reject/class`,
  });
};

export const allIncome = (builder) => {
  return builder.query({
    query: (data) =>
      `/finance/${data.fid}/dashboard/income?page=${data.page}&limit=${data.limit}`,
  });
};

export const allIBalance = (builder) => {
  return builder.query({
    query: (finance) => `/finance/${finance}/dashboard/income/balance`,
  });
};

export const oneIncome = (builder) => {
  return builder.query({
    query: (income) => `/finance/${income}/income/detail`,
  });
};

export const addIncome = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/finance/${data?.fid}/income?user_query=${data.user_query}`,
      method: "POST",
      body: data?.fData,
    }),
  });
};

export const allExpense = (builder) => {
  return builder.query({
    query: (data) =>
      `/finance/${data.fid}/dashboard/expense?page=${data.page}&limit=${data.limit}`,
  });
};

export const allEBalance = (builder) => {
  return builder.query({
    query: (finance) => `/finance/${finance}/dashboard/expense/balance`,
  });
};

export const oneExpense = (builder) => {
  return builder.query({
    query: (expense) => `/finance/${expense}/expense/detail`,
  });
};

export const addExpense = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/finance/${data?.fid}/expense?is_inventory=${data.is_inventory}&user_query=${data.user_query}`,
      method: "POST",
      body: data?.fData,
    }),
  });
};

export const salHistory = (builder) => {
  return builder.query({
    query: (finance) => `/finance/${finance}/sal/history`,
  });
};

export const oneEmpAndOneSalary = (builder) => {
  return builder.query({
    query: (emp) =>
      `/finance/${emp?.eid}/one/emp/detail?type=${emp?.type}&month=${emp?.month}`,
  });
};

export const allEmp = (builder) => {
  return builder.query({
    query: (finance) =>
      `/finance/${finance?.fid}/emp/all?page=${finance?.page}&limit=${finance?.limit}`,
  });
};

export const allEmpStaff = (builder) => {
  return builder.query({
    query: (finance) => `/finance/${finance}/all/staff/array`,
  });
};

export const addEmployeePayrollFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/finance/${data.fid}/add/emp/${data.sid}`,
      method: "POST",
      body: data.employeePayroll,
    }),
  });
};
export const allHistoryFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `/pay/history/to?uid=${data.uid}&page=${data.page}&limit=${data.limit}&filter=${data.filter}`,
  });
};

export const addPayrollFunction = (builder) => {
  return builder.mutation({
    query: (data) => ({
      url: `/finance/${data.fid}/add/payroll/${data.eid}`,
      method: "POST",
      body: data.addPayroll,
    }),
  });
};

export const oneStaffAttendanceFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `/attendance/staff/onemonth/${data.sid}?month=${data.month}&year=${data.year}`,
  });
};

export const merchantIdFunction = (builder) => {
  return builder.query({
    query: (id) => `/pay/merchant/author/${id}`,
  });
};

export const inventoryListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `/finance/${data.fid}/all/inventory/query?page=${data.page}&limit=${data.limit}`,
  });
};

export const oneInventoryExpneseListFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `/finance/one/inventory/${data.inid}/query?page=${data.page}&limit=${data.limit}`,
  });
};
