import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseUrl, prepareHeaders } from "./configApi";
import {
  activateFinance,
  editFinanceFunction,
  fetchFinance,
  uploadBank,
  uploadEditBank,
  removeBankDetail,
  paymentDetail,
  remainBalance,
  dueBalance,
  remainStudentList,
  submissionRequest,
  acceptQuery,
  rejectQuery,
  acceptArray,
  rejectArray,
  allIncome,
  allIBalance,
  oneIncome,
  addIncome,
  allExpense,
  allEBalance,
  oneExpense,
  addExpense,
  salHistory,
  oneEmpAndOneSalary,
  allEmp,
  allEmpStaff,
  addEmployeePayrollFunction,
  allHistoryFunction,
  addPayrollFunction,
  oneStaffAttendanceFunction,
  merchantIdFunction,
  inventoryListFunction,
  oneInventoryExpneseListFunction,
} from "./financeAPIBuilder";

export const financeAPI = createApi({
  reducerPath: "financeAPI",
  baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders: prepareHeaders }),
  endpoints: (builder) => ({
    activateFinanceHead: activateFinance(builder),
    editFinanceHead: editFinanceFunction(builder),
    fetchFinanceDetail: fetchFinance(builder),
    uploadFinanceBank: uploadBank(builder),
    financeEditBank: uploadEditBank(builder),
    financeRemoveBank: removeBankDetail(builder),
    getPaymentDetail: paymentDetail(builder),
    fetchFinanceRemainBalance: remainBalance(builder),
    fetchDueBalance: dueBalance(builder),
    remainingStudentFeeList: remainStudentList(builder),
    submissionRequestArray: submissionRequest(builder),
    acceptRequest: acceptQuery(builder),
    rejectRequest: rejectQuery(builder),
    acceptRequestArray: acceptArray(builder),
    rejectRequestArray: rejectArray(builder),
    getAllIncome: allIncome(builder),
    getAllIBalance: allIBalance(builder),
    oneIncomeDetail: oneIncome(builder),
    addIncomeData: addIncome(builder),
    getAllExpense: allExpense(builder),
    getAllEBalance: allEBalance(builder),
    oneExpenseDetail: oneExpense(builder),
    addExpenseData: addExpense(builder),
    getAllSalaryHistory: salHistory(builder),
    getOneEmpDetailAndOneSalaryHistory: oneEmpAndOneSalary(builder),
    getAllEmployee: allEmp(builder),
    getAllEmployeeStaff: allEmpStaff(builder),
    addEmployeePayroll: addEmployeePayrollFunction(builder),
    allHistory: allHistoryFunction(builder),
    addPayroll: addPayrollFunction(builder),
    oneStaffAttendance: oneStaffAttendanceFunction(builder),
    merchantId: merchantIdFunction(builder),
    inventoryList: inventoryListFunction(builder),
    oneInventoryExpneseList: oneInventoryExpneseListFunction(builder),
  }),
});

export const {
  useActivateFinanceHeadMutation,
  useEditFinanceHeadMutation,
  useFetchFinanceDetailQuery,
  useUploadFinanceBankMutation,
  useFinanceEditBankMutation,
  useFinanceRemoveBankMutation,
  useGetPaymentDetailQuery,
  useFetchFinanceRemainBalanceQuery,
  useFetchDueBalanceQuery,
  useRemainingStudentFeeListQuery,
  useSubmissionRequestArrayQuery,
  useAcceptRequestMutation,
  useRejectRequestMutation,
  useAcceptRequestArrayQuery,
  useRejectRequestArrayQuery,
  useGetAllIncomeQuery,
  useGetAllIBalanceQuery,
  useOneIncomeDetailQuery,
  useAddIncomeDataMutation,
  useGetAllExpenseQuery,
  useGetAllEBalanceQuery,
  useOneExpenseDetailQuery,
  useAddExpenseDataMutation,
  useGetAllSalaryHistoryQuery,
  useGetOneEmpDetailAndOneSalaryHistoryQuery,
  useGetAllEmployeeQuery,
  useGetAllEmployeeStaffQuery,
  useAddEmployeePayrollMutation,
  useAllHistoryQuery,
  useAddPayrollMutation,
  useOneStaffAttendanceQuery,
  useMerchantIdQuery,
  useInventoryListQuery,
  useOneInventoryExpneseListQuery,
} = financeAPI;
