import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, patchMethod, postMethod, session } from "../BaseUrl";
import {
  subjectAllAssignmentFunction,
  subjectAllExamFunction,
  subjectAssignmentSubmitAllStudentFunction,
  subjectAssignmentSubmitOneStudentDetailFunction,
  subjectCompleteFunction,
  subjectCreateAssignmentFunction,
  subjectAssignmentSubmitCountFunction,
  subjectAssignmentSubmitOneStudentActionFunction,
  subjectOneExamAllStudentFunction,
  subjectOneExamMarksFunction,
  subjectProfileFunction,
  mcqProfileFunction,
  mcqProfileUpdateFunction,
  mcqUniversalDepartmentFunction,
  mcqUniversalClassFunction,
  mcqUniversalSubjectFunction,
  mcqQuestionFunction,
  mcqOneQuestionFunction,
  mcqCreateQuestionFunction,
  mcqQuestionCountFunction,
  mcqTestsetFunction,
  mcqCreateTestsetFunction,
  mcqTestsetDetailFunction,
  mcqTestsetTakenFunction,
  mcqTestsetTakenListFunction,
  mcqTestsetAllotedFunction,
} from "./subject_teacher_query_mutation_function";

export const subjectApi = createApi({
  reducerPath: "subjectApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      if (session()) {
        headers.set("authorization", session());
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    subjectProfile: subjectProfileFunction(builder),
    subjectAllExam: subjectAllExamFunction(builder),
    subjectOneExamStudents: subjectOneExamAllStudentFunction(builder),
    subjectOneExamMarks: subjectOneExamMarksFunction(builder, postMethod),
    subjectComplete: subjectCompleteFunction(builder, patchMethod),
    subjectAllAssignment: subjectAllAssignmentFunction(builder),
    subjectCreateAssignment: subjectCreateAssignmentFunction(
      builder,
      postMethod
    ),
    subjectAssignmentSubmitCount: subjectAssignmentSubmitCountFunction(builder),
    subjectAssignmentSubmitAllStudent:
      subjectAssignmentSubmitAllStudentFunction(builder),
    subjectAssignmentSubmitOneStudentDetail:
      subjectAssignmentSubmitOneStudentDetailFunction(builder),
    subjectAssignmentSubmitOneStudentAction:
      subjectAssignmentSubmitOneStudentActionFunction(builder, patchMethod),
    mcqProfile: mcqProfileFunction(builder),
    mcqProfileUpdate: mcqProfileUpdateFunction(builder, patchMethod),
    mcqUniversalDepartment: mcqUniversalDepartmentFunction(builder),
    mcqUniversalClass: mcqUniversalClassFunction(builder),
    mcqUniversalSubject: mcqUniversalSubjectFunction(builder),
    mcqQuestion: mcqQuestionFunction(builder),
    mcqOneQuestion: mcqOneQuestionFunction(builder),
    mcqCreateQuestion: mcqCreateQuestionFunction(builder, postMethod),
    mcqQuestionCount: mcqQuestionCountFunction(builder),
    mcqTestset: mcqTestsetFunction(builder),
    mcqCreateTestset: mcqCreateTestsetFunction(builder, postMethod),
    mcqTestsetDetail: mcqTestsetDetailFunction(builder),
    mcqTestsetTaken: mcqTestsetTakenFunction(builder, postMethod),
    mcqTestsetTakenList: mcqTestsetTakenListFunction(builder),
    mcqTestsetAlloted: mcqTestsetAllotedFunction(builder),
  }),
});

export const {
  useSubjectprofileQuery,
  useSubjectAllExamQuery,
  useSubjectOneExamStudentsQuery,
  useSubjectOneExamMarksMutation,
  useSubjectCompleteMutation,
  useSubjectAllAssignmentQuery,
  useSubjectCreateAssignmentMutation,
  useSubjectAssignmentSubmitCountQuery,
  useSubjectAssignmentSubmitAllStudentQuery,
  useSubjectAssignmentSubmitOneStudentDetailQuery,
  useSubjectAssignmentSubmitOneStudentActionMutation,
  useMcqProfileQuery,
  useMcqProfileUpdateMutation,
  useMcqUniversalDepartmentQuery,
  useMcqUniversalClassQuery,
  useMcqUniversalSubjectQuery,
  useMcqQuestionQuery,
  useMcqOneQuestionQuery,
  useMcqCreateQuestionMutation,
  useMcqQuestionCountQuery,
  useMcqTestsetQuery,
  useMcqCreateTestsetMutation,
  useMcqTestsetDetailQuery,
  useMcqTestsetTakenMutation,
  useMcqTestsetTakenListQuery,
  useMcqTestsetAllotedQuery,
} = subjectApi;
