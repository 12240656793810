// export const baseUrl = "http://localhost:8080/api/v1";
// export const baseUrl = "http://18.205.27.165/api/api/v1";
export const baseUrl = "https://qviple.com/api/v1";

export const prepareHeaders = (headers) => {
  var token = localStorage.getItem("token");
  setTimeout(() => {
    if (token) {
      return "";
    } else {
      token = localStorage.getItem("token");
    }
  }, [100]);
  if (token) {
    headers.set("authorization", `${token}`);
  }
  return headers;
};
