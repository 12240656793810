const departmentUrl = "/api/v1/ins";
const imageUrlPath = "/api/v1/all-images";
const holidayUrl = "/api/v1/attendance";
const checklistUrl = "/api/v1/checklist";
const examUrl = "/api/v1/exam";
const complaintUrl = "/api/v1/compleave";
const feesUrl = "/api/v1/fees";

export const departmentProfileFunction = (builder) => {
  return builder.query({
    query: (did) => `${departmentUrl}/staffdepartment/${did}`,
  });
};

export const departmentProfilePhotoFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${imageUrlPath}/${data.did}/department/photo`,
      method: `${method}`,
      body: data.profile,
    }),
  });
};
export const departmentCoverPhotoFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${imageUrlPath}/${data.did}/department/cover`,
      method: `${method}`,
      body: data.cover,
    }),
  });
};

export const departmentInfoUpdateFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${departmentUrl}/staff/department-info/${data.did}`,
      method: `${method}`,
      body: data.departmentInfo,
    }),
  });
};

export const departmentAddDisplayPersonFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${departmentUrl}/${data.did}/department/display-person`,
      method: `${method}`,
      body: data.addPerson,
    }),
  });
};

export const departmentAllStaffFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${departmentUrl}/department/${data.did}/staff-array?page=${data.page}&limit=${data.limit}`,
  });
};

export const departmentBatchAllClassFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${departmentUrl}/batch-detail/${data.bid}?page=${data.page}&limit=${data.limit}`,
  });
};

export const departmentCreatedHolidayFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${holidayUrl}/department/${data.did}/holiday`,
      method: `${method}`,
      body: data.holiday,
    }),
  });
};

export const departmentAllHolidayFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${holidayUrl}/holiday/${data.did}?page=${data.page}&limit=${data.limit}`,
  });
};

export const departmentDeleteHolidayFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${holidayUrl}/holiday/${data.hid}/delete`,
      method: `${method}`,
    }),
  });
};

export const departmentCreatedChecklistFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${checklistUrl}/department/${data.did}`,
      method: `${method}`,
      body: data.createChecklist,
    }),
  });
};

export const departmentAllChecklistFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${checklistUrl}/department/${data.did}/all?page=${data.page}&limit=${data.limit}`,
  });
};

export const departmentUpadateChecklistFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${checklistUrl}/${data.cid}`,
      method: `${method}`,
      body: data.checklist,
    }),
  });
};

export const departmentAllClassMasterFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${examUrl}/${data.did}/classmaster/${data.bid}?page=${data.page}&limit=${data.limit}`,
  });
};
export const departmentAllSubjectMasterFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${examUrl}/${data.cmid}/subjectmaster/${data.bid}?page=${data.page}&limit=${data.limit}`,
  });
};

export const departmentCreateExamFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${examUrl}/batch/${data.bid}`,
      method: `${method}`,
      body: data.exam,
    }),
  });
};

export const departmentAllExamFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${examUrl}/all/${data.did}?page=${data.page}&limit=${data.limit}`,
  });
};

export const departmentExamDetailFunction = (builder) => {
  return builder.query({
    query: (data) => `${examUrl}/${data.eid}`,
  });
};

export const departmentAllComplaintFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${complaintUrl}/department/${data.did}/complaint?status=${data.status}&page=${data.page}&limit=${data.limit}`,
  });
};

export const departmentComplaintDetailFunction = (builder) => {
  return builder.query({
    query: (data) => `${complaintUrl}/complaint/${data.cid}`,
  });
};

export const departmentComplaintSolveFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${complaintUrl}/complaint/${data.cid}`,
      method: `${method}`,
      body: data.complaintSolve,
    }),
  });
};

export const departmentAllFeesFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${feesUrl}/department/${data.did}/query?page=${data.page}&limit=${data.limit}`,
  });
};

export const departmentCreateFeesFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${feesUrl}/department-class/fee/${data.did}`,
      method: `${method}`,
      body: data.fees,
    }),
  });
};
