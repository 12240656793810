// export const baseUrl = "http://18.205.27.165/api";
export const baseUrl = "https://qviple.com";
// export const baseUrl = "http://localhost:8080";

export const postMethod = "POST";
export const patchMethod = "PATCH";
export const putMethod = "PUT";
export const deleteMethod = "DELETE";

export const session = () => {
  var token = localStorage.getItem("token");
  setTimeout(() => {
    if (token) {
      return "";
    } else {
      token = localStorage.getItem("token");
    }
  }, [100]);
  return token;
};

// export const imageShowUrl1 = "http://18.205.27.165/api/api/v1/all-images";
// export const imageShowUrl = "http://18.205.27.165/api/api/v1/all-images";
export const imageShowUrl1 = "https://qviple.com/api/v1/all-images";
export const imageShowUrl = "https://d3dqpu54js2vfl.cloudfront.net";

// export const imageShowUrl1 = "http://localhost:8080/api/v1/all-images";
// export const imageShowUrl = "http://localhost:8080/api/v1/all-images";
