import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import {
  baseUrl,
  // deleteMethod,
  patchMethod,
  // postMethod,
  session,
} from "../BaseUrl";
import {
  studentPreviousReportList,
  studentPreviousReportCard,
  mcqAllTestsetFunction,
  mcqTestsetPreviewDetailFunction,
  mcqTestsetExamDetailFunction,
  mcqQuestionGivenFunction,
  mcqTestsetExamSubmitFunction,
  mcqTestsetExamResultFunction,
} from "./studentMembersTabApiBuilder";

export const studentMembersTabApi = createApi({
  reducerPath: "studentMembersTabApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      if (session()) {
        headers.set("authorization", session());
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    studentPreviousReportList: studentPreviousReportList(builder),
    studentPreviousReportCard: studentPreviousReportCard(builder),
    mcqAllTestset: mcqAllTestsetFunction(builder),
    mcqTestsetPreviewDetail: mcqTestsetPreviewDetailFunction(builder),
    mcqTestsetExamDetail: mcqTestsetExamDetailFunction(builder),
    mcqQuestionGiven: mcqQuestionGivenFunction(builder, patchMethod),
    mcqTestsetExamSubmit: mcqTestsetExamSubmitFunction(builder, patchMethod),
    mcqTestsetExamResult: mcqTestsetExamResultFunction(builder),
  }),
});

export const {
  useStudentPreviousReportCardQuery,
  useStudentPreviousReportListQuery,
  useMcqAllTestsetQuery,
  useMcqTestsetPreviewDetailQuery,
  useMcqTestsetExamDetailQuery,
  useMcqQuestionGivenMutation,
  useMcqTestsetExamSubmitMutation,
  useMcqTestsetExamResultQuery,
} = studentMembersTabApi;
