const postUrl = "/api/v1";
const mcqUrl = "/api/v1/mcq/student";
// var id = localStorage.getItem("user");

// -------------------  Student Previous Report List in Student Side--------------------------------------------

export const studentPreviousReportList = (builder) => {
  return builder.query({
    query: (data) => `${postUrl}/edit/student/${data.sid}/previous`,
  });
};

// -------------------  Student Previous Report Card in Student Side--------------------------------------------

export const studentPreviousReportCard = (builder) => {
  return builder.query({
    query: (data) => `${postUrl}/edit/student/${data.pid}/previous/report`,
  });
};

// MCQ Things

export const mcqAllTestsetFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${mcqUrl}/${data.sid}/alltestset?page=${data.page}&limit=${data.limit}`,
  });
};

export const mcqTestsetPreviewDetailFunction = (builder) => {
  return builder.query({
    query: (tsid) => `${mcqUrl}/testset/${tsid}/detail`,
  });
};

export const mcqTestsetExamDetailFunction = (builder) => {
  return builder.query({
    query: (tsid) => `${mcqUrl}/testset/paper/${tsid}`,
  });
};
export const mcqQuestionGivenFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${mcqUrl}/testset/paper/${data.tsid}`,
      method: `${method}`,
      body: data.correctAnswer,
    }),
  });
};

export const mcqTestsetExamSubmitFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${mcqUrl}/testset/${data.tsid}/complete`,
      method: `${method}`,
      body: data.submitTestset,
    }),
  });
};

export const mcqTestsetExamResultFunction = (builder) => {
  return builder.query({
    query: (tsid) => `${mcqUrl}/testset/${tsid}/complete`,
  });
};
