const postUrl = "/api/v1/ins";
const postUrl1 = "/api/v1";
const postUserUrl = "/api/v1/user";

export const allDepartmentFunction = (builder) => {
  return builder.query({
    query: (id) => `${postUrl}/${id}/department`,
  });
};

export const addDepartmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/${data.id}/new-department`,
      method: `${method}`,
      body: data.departmentCreate,
    }),
  });
};
export const oneDepartmentFunction = (builder) => {
  return builder.query({
    query: (did) => `${postUrl}/department-detail/${did}`,
  });
};
export const allBatchDepartmentFunction = (builder) => {
  return builder.query({
    query: (did) => `${postUrl}/${did}/one-batch`,
  });
};
export const addBatchDepartmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/addbatch/${data.did}/ins/${data.id}`,
      method: `${method}`,
      body: data.batch,
    }),
  });
};

export const addIdenticalBatchDepartmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/batch/${data.bid}`,
      method: `${method}`,
      body: data.batch,
    }),
  });
};

export const currentBatchDepartmentFunction = (builder) => {
  return builder.query({
    query: (id, bid) => `${postUrl}/${id}/batchdetail/${bid}`,
  });
};
export const setCurrentBatchDepartmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/${data.did}/batch-select/${data.bid}`,
      method: `${method}`,
      body: data.batch,
    }),
  });
};
export const addClassMasterDepartmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/${data.id}/departmentmasterclass/${data.did}`,
      method: `${method}`,
      body: data.className,
    }),
  });
};
export const allClassMasterDepartmentFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${postUrl}/${data.id}/departmentmasterclass-detail/${data.did}`,
  });
};
export const addSubjectMasterDepartmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/${data.id}/departmentmastersubject/${data.did}`,
      method: `${method}`,
      body: data.subject,
    }),
  });
};
export const allSubjectMasterDepartmentFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${postUrl}/${data.id}/departmentmastersubject/${data.did}`,
  });
};
export const addClassDepartmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/${data.id}/department/${data.did}/batch/${data.bid}`,
      method: `${method}`,
      body: data.classCreate,
    }),
  });
};
export const oneBatchClassDepartmentFunction = (builder) => {
  return builder.query({
    query: (bid) => `${postUrl}/batch/class/${bid}`,
  });
};
export const addSubjectClassDepartmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/${data.id}/department/${data.did}/batch/${data.bid}/class/${data.cid}/subject`,
      method: `${method}`,
      body: data.subjectAdd,
    }),
  });
};
export const oneClassProfileDepartmentFunction = (builder) => {
  return builder.query({
    query: (cid) => `${postUrl}/class/${cid}`,
  });
};
export const oneClassAllSubjectDepartmentFunction = (builder) => {
  return builder.query({
    query: (cid) => `${postUrl}/class/subject/${cid}`,
  });
};
export const allApproveStaffDepartmentFunction = (builder) => {
  return builder.query({
    query: (id) => `${postUrl}/${id}/approve-staff/list`,
  });
};

//FOR DEPARTMENT ALL EDIT FUNCTIONALITY
export const getDetailDepartmentFunction = (builder) => {
  return builder.query({
    query: (did) => `${postUrl1}/edit/institute/department/${did}`,
  });
};

export const editDepartmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/institute/department/${data.did}`,
      method: `${method}`,
      body: data.department,
    }),
  });
};
export const deleteDepartmentFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/institute/department/${data.did}`,
      method: `${method}`,
      body: "",
    }),
  });
};

export const editBatchFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/institute/batch/${data.bid}`,
      method: `${method}`,
      body: data.batch,
    }),
  });
};
export const deleteBatchFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/institute/batch/${data.bid}`,
      method: `${method}`,
      body: "",
    }),
  });
};

export const deleteClassMasterFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/institute/classmaster/${data.cmid}`,
      method: `${method}`,
      body: "",
    }),
  });
};

export const getDetailClassFunction = (builder) => {
  return builder.query({
    query: (cid) => `${postUrl1}/edit/institute/class/${cid}`,
  });
};

export const editClassFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/institute/class/${data.cid}`,
      method: `${method}`,
      body: data.classDetail,
    }),
  });
};
export const deleteClassFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/institute/class/${data.cid}`,
      method: `${method}`,
      body: "",
    }),
  });
};

export const deleteSubjectMasterFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/institute/subjectmaster/${data.smid}`,
      method: `${method}`,
      body: "",
    }),
  });
};

export const getDetailSubjectFunction = (builder) => {
  return builder.query({
    query: (sid) => `${postUrl1}/edit/institute/subject/${sid}`,
  });
};

export const editSubjectFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/institute/subject/${data.sid}`,
      method: `${method}`,
      body: data.subject,
    }),
  });
};
export const deleteSubjectFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/institute/subject/${data.sid}`,
      method: `${method}`,
      body: "",
    }),
  });
};

//FOR THE INSTITUTE PROFILE UPDATE

export const instituteProfileFunction = (builder) => {
  return builder.query({
    query: (id) => `${postUrl}/${id}/profile`,
  });
};

export const updateInstituteProfileFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/personal/info/${data.id}`,
      method: `${method}`,
      body: data.infoUpdate,
    }),
  });
};

//FOR THE INSTITUTE COMPLAINT OF STAFF
export const instituteAllStaffComplaintFunction = (builder) => {
  return builder.query({
    query: (data) =>
      `${postUrl1}/compleave/institute/${data.id}/staff/complaint?status=${data.status}`,
  });
};
export const instituteStaffComplaintDetailFunction = (builder) => {
  return builder.query({
    query: (cid) => `${postUrl1}/compleave/staff/complaint/${cid}`,
  });
};

export const instituteStaffComplaintSolveFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/compleave/staff/complaint/${data.cid}`,
      method: `${method}`,
      body: data.complaintStatus,
    }),
  });
};

//FOR THE INSTITUTE Form Setting OF STAFF and STUDENT
export const instituteStaffFormFunction = (builder) => {
  return builder.query({
    query: (id) => `${postUrl}/${id}/staff/form`,
  });
};
export const instituteStudentFormFunction = (builder) => {
  return builder.query({
    query: (id) => `${postUrl}/${id}/student/form`,
  });
};

export const instituteFormSettingUpdateFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl}/${data.id}/form/setting/update`,
      method: `${method}`,
      body: data.formSetting,
    }),
  });
};

export const userProfileFunction = (builder) => {
  return builder.query({
    query: (id) => `${postUserUrl}/${id}/profile`,
  });
};
export const updateUserProfileFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUserUrl}/personal/info/${data.id}`,
      method: `${method}`,
      body: data.infoUpdate,
    }),
  });
};

// /staff form edit

export const staffProfilePhotoFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/staff/form/photo/${data.sid}`,
      method: `${method}`,
      body: data.profile,
    }),
  });
};

export const staffFormEditFunction = (builder, method) => {
  return builder.mutation({
    query: (data) => ({
      url: `${postUrl1}/edit/staff/form/detail/${data.sid}`,
      method: `${method}`,
      body: data.editProfile,
    }),
  });
};
